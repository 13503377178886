import {
  Box,
  Center,
  Heading,
  Grid,
  SimpleGrid,
  Stack,
  useColorModeValue as mode,
} from '@chakra-ui/react'
import * as React from 'react'
import { AiFillCheckCircle } from 'react-icons/ai'
import Feature from './Features/Feature'
import UnderlineLink from 'pages/home/utils/UnderlineLink'

export default function Benefits() {
  return (
    <Box as="section" bg={mode('#FFFFFF', 'gray.800')} pt="16" pb="32" id='features'>
      <Box maxW={{ base: 'xl', md: '7xl' }} mx="auto" px={{ base: '6', md: '8' }}>
        <Heading textAlign="center" letterSpacing="tight" fontWeight="extrabold">
          What we {' '}
          <UnderlineLink>
            offer.
          </UnderlineLink>
        </Heading>
        <Box mt="24">
          <SimpleGrid
            columns={{ base: 1, sm: 1, md: 2 }}
            mt="8"
            spacing="6"
            color={mode('inherit', 'white')}
          >
              <Feature
                icon={<AiFillCheckCircle />}
                title="Accounts and addresses"
                iconDarkMode='brand.500'
                iconLightMode='brand.500'
                textDarkMode='gray.400'
                textLightMode='gray.700'
              >
                Retrieve users’ addresses of custodial and non-custodial wallets when they connect to your app.
              </Feature>
              <Feature
                icon={<AiFillCheckCircle />}
                title="Transactions and history"
                iconDarkMode='brand.500'
                iconLightMode='brand.500'
                textDarkMode='gray.400'
                textLightMode='gray.700'
              >
                Retrieve transaction history directly from blockchain protocols or users’ exchange accounts.
              </Feature>
              <Feature
                icon={<AiFillCheckCircle />}
                title="Send and receive crypto (Coming Soon)"
                iconDarkMode='gray.500'
                iconLightMode='gray.300'
                textDarkMode='gray.500'
                textLightMode='gray.300'
              >
                Allow users to send and receive crypto from their exchange accounts without leaving your app.
              </Feature>
              <Feature
                icon={<AiFillCheckCircle />}
                title="Sign Transactions (Coming Soon)"
                iconDarkMode='gray.500'
                iconLightMode='gray.300'
                textDarkMode='gray.500'
                textLightMode='gray.300'
              >
                Perform transactions on the blockchain with user’s non-custodial wallets.
              </Feature>
          </SimpleGrid>
        </Box>

        {/* <Box mt="24">
          <SimpleGrid
            columns={{ base: 1, sm: 1, md: 2 }}
            mt="8"
            spacing="6"
            color={mode('inherit', 'white')}
          >
            <Box w="100%" h="10" bg="blue.500" />
            <Box w="100%" h="10" bg="blue.500" />
            <Box w="100%" h="10" bg="red.500" />
            <Box w="100%" h="10" bg="red.500" />
          </SimpleGrid>
        </Box> */}
      </Box>
    </Box>
  )
}