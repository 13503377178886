import { Box, Stack, Text, useColorModeValue as mode } from '@chakra-ui/react'
import * as React from 'react'

interface FeatureProps {
  icon: React.ReactElement
  title: string
  children: React.ReactNode,
  iconDarkMode: string,
  iconLightMode: string,
  textDarkMode: string,
  textLightMode: string
}

export default function Feature(props: FeatureProps) {
  const { title, children, icon, iconDarkMode, iconLightMode, textDarkMode, textLightMode } = props
  return (
    <Stack direction="row" w="100%" spacing="6">
      <Box color={mode(iconLightMode, iconDarkMode)} fontSize="2.5rem">
        {icon}
      </Box>
      <Stack>
        <Text as="h3" fontSize="xl" fontWeight="extrabold" color={mode(iconLightMode, iconDarkMode)}>
          {title}
        </Text>
        <Text pr="6" color={mode(textLightMode, textDarkMode)} lineHeight="tall">
          {children}
        </Text>
      </Stack>
    </Stack>
  )
}